.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.modal-header, .modal-body, .modal-footer {
    padding: 10px;
}

.modal-header {
    background-color: #5cb85c;
    color: white;
}

.modal-title {
    font-size: 24px;
    font-weight: bold;
}

.modal-body {
    font-size: 16px;
    color: #333;
}

.modal-footer {
    background-color: #f2f2f2;
}

.modal-close {
    background-color: #5cb85c;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.modal-close:hover {
    background-color: #45a049;
}
